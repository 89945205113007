function initTab(elem) {


    var index = 0;
    var $tabs = jQuery('a.tab-button');

    $tabs.bind({
        // on keydown,
        // determine which tab to select
        keydown: function (ev) {
            var LEFT_ARROW = 37;
            var UP_ARROW = 38;
            var RIGHT_ARROW = 39;
            var DOWN_ARROW = 40;

            var k = ev.which || ev.keyCode;

            // if the key pressed was an arrow key
            if (k >= LEFT_ARROW && k <= DOWN_ARROW) {
                // move left one tab for left and up arrows
                if (k == LEFT_ARROW || k == UP_ARROW) {
                    if (index > 0) {
                        index--;
                    }
                    // unless you are on the first tab,
                    // in which case select the last tab.
                    else {
                        index = $tabs.length - 1;
                    }
                }

                // move right one tab for right and down arrows
                else if (k == RIGHT_ARROW || k == DOWN_ARROW) {
                    if (index < ($tabs.length - 1)) {
                        index++;
                    }
                    // unless you're at the last tab,
                    // in which case select the first one
                    else {
                        index = 0;
                    }
                }

                // trigger a click event on the tab to move to
                jQuery($tabs.get(index)).click();
                ev.preventDefault();
            }
        },

        // just make the clicked tab the selected one
        click: function (ev) {
            index = jQuery.inArray(this, $tabs.get());
            setFocus();
            ev.preventDefault();
        }
    });

    var setFocus = function () {
        // undo tab control selected state,
        // and make them not selectable with the tab key
        // (all tabs)
        $tabs.attr({
            tabindex: '-1',
            'aria-selected': 'false'
        }).removeClass('active');

        // hide all tab panels.
        jQuery('.tab-panel').removeClass('active');

        // make the selected tab the selected one, shift focus to it
        jQuery($tabs.get(index)).attr({
            tabindex: '0',
            'aria-selected': 'true'
        }).addClass('active').focus();

        // handle parent <li> active class (for coloring the tabs)
        jQuery($tabs.get(index)).parent().siblings().removeClass('active');
        jQuery($tabs.get(index)).parent().addClass('active');

        // add a active class also to the tab panel
        // controlled by the clicked tab
        jQuery(jQuery($tabs.get(index)).attr('href')).addClass('active');
    };
}

initTab('.tabs');


jQuery(window).on('resize', function () {

    var maxHeight = 0,
        items = jQuery('.tab-panel');

    items.each(function () {
        maxHeight = (jQuery(this).find('.inner').innerHeight() > maxHeight ? jQuery(this).find('.inner').innerHeight() : maxHeight);
    });

    //Assign maximum height to children 
    items.height(maxHeight);

    //Assign the largest height to the parent only
    jQuery('.tab-panel-container').height(maxHeight);
});

jQuery(window).trigger('resize');