const MultiStepForm = {
  data() {
    return {
      step: 1,
      radioError: 0,
      errors: [],
      formData: {
        question1: {
          answer: '',
          comment: ''
        },
        question2: {
          answer: '',
          comment: ''
        },
        question3: {
          answer: '',
          comment: ''
        },
        question4: {
          answer: '',
          comment: ''
        },
        details: {
          fullName: '',
          email: '',
          phone: '',
          addressLine1: '',
          addressLine2: '',
          town: '',
          postcode: '',
          privacy: []
        }
      }
    };
  },
  methods: {
    prev() {
      this.radioError = false;
      this.step--;
      this.scroll();
    },
    next() {
      if (this.isChecked(this.step)) {
        this.radioError = false;
        this.step++;
        this.scroll();
      } else {
        this.radioError = true;
        this.scroll();
      }

    },
    submit() {
      jQuery.post("/ajax/submit-survey.php", this.formData, function () {
        vm.step++;
      }).fail(function (data) {
        vm.errors = JSON.parse(data.responseText);
        vm.scroll();
      });

    },
    scroll() {
      setTimeout(function () {
        jQuery('html,body').animate({
          scrollTop: jQuery(".step-" + vm.step).offset().top - 20
        });
      }, 50);
    },
    isChecked(step) {
      return (this.formData['question' + step].answer === '0' || this.formData['question' + step].answer === '1') ? true : false;
    }
  },
  mounted: function () {
    vm = this;
  }
};

//const app = Vue.createApp(MultiStepForm).mount('#multi-step-form');